import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FilterIcon } from '@app/components/common/icons/FilterIcon';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col } from 'antd';
import { OrganizationsFilterState } from '@app/components/main-dashboard/organizations/Organizations';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as S from './OrganizationsHeader.styles';
import { PrevButton } from '@app/components/forms/StepForm/StepForm.styles';

interface OrganizationsHeaderProps {
  filters: OrganizationsFilterState;
  setFilters: (func: (state: OrganizationsFilterState) => OrganizationsFilterState) => void;
}

export const OrganizationsHeader: React.FC<OrganizationsHeaderProps> = ({ filters, setFilters }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation();
  const onSearch = (value: string) => {
    setFilters((prev) => ({ ...prev, search: value }));
  };

  return (
    <>
      <S.WrapperRow justify="space-between">
        <Col>
          <S.Title level={5}>{t('main.organizationsTitle')}</S.Title>
        </Col>
        <Col>
          <SearchInput placeholder={t('inputs.searchText')} allowClear onSearch={onSearch} />
        </Col>
        <Col>
          <Button size="large" noStyle type="text" icon={<FilterIcon />} onClick={() => setModalVisible(true)} />
        </Col>
      </S.WrapperRow>
      <Modal visible={isModalVisible} onCancel={() => setModalVisible(false)} footer={null}>
        <p>TODO: the publishers order and filter.</p>
      </Modal>
    </>
  );
};
