import React from 'react';
import { getAppsKeysData, updateAppKey, deleteAppKey, AppsKeysRow } from 'api/sdk.api';
import { useTranslation } from 'react-i18next';
import { Dates } from '@app/constants/Dates';
import { EditTable } from './EditTable/EditTable';

export const AppsKeysTable: React.FC = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('common.id'),
      dataIndex: 'id',
      width: '5%',
      inputType: 'text',
      editable: false,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.app_id'),
      dataIndex: 'app_id',
      width: '35%',
      inputType: 'number',
      editable: true,
      required: true,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.key_id'),
      dataIndex: 'key_id',
      width: '35%',
      inputType: 'number',
      editable: true,
      required: true,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.updatedOn'),
      dataIndex: 'updated_on',
      width: '10%',
      editable: false,
      render: (v: Date) => Dates.getDate(v).format('lll'),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.createdOn'),
      dataIndex: 'created_on',
      width: '10%',
      editable: false,
      render: (v: Date) => Dates.getDate(v).format('lll'),
      sorter: true,
      showSorterTooltip: false,
    },
  ];

  const rowParser = (row: any): AppsKeysRow => {
    // do all the parsing here - I don't know how to do in react
    return row as AppsKeysRow;
  };

  return (
    <EditTable
      getData={getAppsKeysData}
      updateData={updateAppKey}
      deleteData={deleteAppKey}
      tableColumns={columns}
      rowParser={rowParser}
    />
  );
};
