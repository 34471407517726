import { Router } from 'react-router-dom';
import { useState, useLayoutEffect } from 'react';
import { BrowserHistory } from 'history';

interface HistoryRouterProps {
  history: BrowserHistory;
  basename?: string;
  children?: React.ReactNode;
}

const HistoryRouter = ({ history, ...props }: HistoryRouterProps): React.ReactElement | null => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

export default HistoryRouter;
