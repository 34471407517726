import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  const expire = useAppSelector((state) => state.auth.expire);
  const hasAuth = token != null && expire != null && expire > new Date().getTime() / 1000;
  return hasAuth ? <>{children}</> : <Navigate to="/auth-admin/login" replace />;
};

export default RequireAuth;
