import React, { useEffect, useMemo, useRef } from 'react';
import { Feed } from '@app/components/common/Feed/Feed';
import { OrganizationsItem } from '@app/components/main-dashboard/organizations/organizationsFeed/OrganizationsItem/OrganizationsItem';
import { OrgDashboardItem } from '@app/api/sdk.api';
import * as S from './OrganizationsFeed.styles';

interface OrganizationsFeedProps {
  organizations: OrgDashboardItem[];
  hasMore: boolean;
  next: () => void;
}

export const OrganizationsFeed: React.FC<OrganizationsFeedProps> = ({ organizations, hasMore, next }) => {
  const activityItems = useMemo(
    () => organizations.map((item, index) => <OrganizationsItem key={index} {...item} />),
    [organizations],
  );

  const feedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (organizations.length < 4) {
      feedRef.current?.dispatchEvent(new CustomEvent('scroll'));
    }
  }, [organizations]);

  return activityItems.length > 0 ? (
    <S.FeedWrapper ref={feedRef} id="organizations-feed">
      <Feed hasMore={hasMore} next={next} target="organizations-feed">
        {activityItems}
      </Feed>
    </S.FeedWrapper>
  ) : (
    <div />
  );
};
