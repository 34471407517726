import React from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { OrganizationsCollection } from '@app/components/main-dashboard/organizations/Organizations';
import * as S from './DashboardPage.styles';
import { TotalOrganizations } from '@app/components/main-dashboard/totalOrganizations/TotalOrganizations';
import { TotalNodes } from '@app/components/main-dashboard/totalNodes/TotalNodes';

const MainDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const desktopLayout = (
    <Row>
      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <Row gutter={[60, 60]}>
          <Col span={24}>
            <OrganizationsCollection />
          </Col>
        </Row>
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <div id="stats-orgs">
          <TotalOrganizations />
        </div>
        <S.Space />
        <div id="stats-nodes">
          <TotalNodes />
        </div>
      </S.RightSideCol>
    </Row>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 24]}>
      <Col span={24}>
        <OrganizationsCollection />
      </Col>
    </Row>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MainDashboardPage;
