import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  loginFromToken,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
} from '@app/api/auth.api';
import { setUser } from '@app/store/slices/userSlice';
import {
  deleteToken,
  deleteUser,
  persistToken,
  readToken,
  persistExpiryDate,
  readExpiryDate,
  deleteExpireDate,
} from '@app/services/localStorage.service';

export interface AuthSlice {
  token: string | null;
  expire: number | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  expire: readExpiryDate(),
};

export const doLogin = createAsyncThunk('auth-admin/doLogin', async (loginPayload: LoginRequest, { dispatch }) => {
  if (loginPayload.token) {
    return loginFromToken(loginPayload).then((res) => {
      dispatch(setUser(res.user));
      persistToken(res.token);
      persistExpiryDate(res.expire);
      return { token: res.token, expire: res.expire };
    });
  } else {
    login(loginPayload).then((res) => {
      dispatch(setUser(res.user));
      persistToken(res.token);
      persistExpiryDate(res.expire);
      return { token: res.token, expire: res.expire };
    });
  }
});

export const doSignUp = createAsyncThunk('auth-admin/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk(
  'auth-admin/doSetNewPassword',
  async (newPasswordData: NewPasswordData) => setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth-admin/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  deleteExpireDate();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload?.token || null;
      state.expire = action.payload?.expire || null;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
      state.expire = null;
    });
  },
});

export default authSlice.reducer;
