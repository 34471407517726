export class ApiError<T> extends Error {
  options?: T;
  status: number | undefined;

  constructor(status: number | undefined, message: string, options?: T) {
    super(message);
    this.status = status;
    this.options = options;
  }
}
