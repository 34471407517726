import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dates } from '@app/constants/Dates';
import * as S from './OrganizationsItem.styles';
import { OrgDashboardItem, UserItem } from '@app/api/sdk.api';
import { activityStatuses } from '@app/constants/config/activityStatuses';
import { createImageFromInitials, getRandomColor } from '@app/utils/utils';
import { Popover } from '@app/components/common/Popover/Popover';
import { Row, Col } from 'antd';
import { Tag } from 'antd';
import { Button } from 'components/common/buttons/Button/Button';
import { Space } from 'antd';
import { getUserToken } from 'api/sdk.api';
import { notification } from 'antd';

const sdkUrl = process.env.REACT_APP_SDK_URL;
const iotUrl = process.env.REACT_APP_IOT_URL;

const impersonate = (target: 'sdk' | 'iot', id: number) => {
  const url = target === 'sdk' ? sdkUrl : target === 'iot' ? iotUrl : '';
  getUserToken(id)
    .then((result) => {
      window.open(url + '/?token=' + result.token, '_blank', 'noreferrer');
    })
    .catch((e) => notification.error({ message: e.message }));
};

export const OrganizationsItem: React.FC<OrgDashboardItem> = (props) => {
  const { t } = useTranslation();

  const currentOrgStatus = useMemo(
    () =>
      activityStatuses.find((dbStatus) => dbStatus.name === (props.verification_status ? 'verified' : 'not-verified')),
    [props.verification_status],
  );

  const lastSeen = props.last_seen ? Dates.getDate(props.last_seen) : null;
  const title = (item: UserItem) => <span>{item.display_name}</span>;
  const content = (item: UserItem) => (
    <div>
      <Space direction="vertical">
        <S.Text>
          {t('common.email')}: {item.email}
        </S.Text>
        <S.Text>{t('common.last_activity')}: </S.Text>
        <Space>
          <Button type="primary" block onClick={() => impersonate('sdk', item.id)}>
            {t('common.impersonate')} SDK
          </Button>
          <Button type="primary" block onClick={() => impersonate('iot', item.id)}>
            {t('common.impersonate')} IOT
          </Button>
        </Space>
      </Space>
    </div>
  );

  return (
    <S.ItemCard>
      <S.Wrapper>
        <S.ImgWrapper>
          <img
            src={createImageFromInitials(84, props.display_name, getRandomColor())}
            alt="img"
            width={84}
            height={84}
          />
        </S.ImgWrapper>
        <S.InfoWrapper>
          <S.InfoHeaderWrapper>
            <S.TitleWrapper>
              <S.Title level={5}>{props.display_name}</S.Title>
              <S.IconWrapper>{currentOrgStatus?.icon}</S.IconWrapper>
            </S.TitleWrapper>
            <Row>
              <Col span={18}>
                <S.Text>ID: {props.id}</S.Text>
                <S.Text>MAU: {props.mau}</S.Text>
                <S.Text>
                  <S.LabelText>Public keys: {props.keys}</S.LabelText>
                  {props.keys_details?.map((item) => (
                    <Tag key={item.id} color="volcano">
                      {item.pubkey}
                    </Tag>
                  ))}
                </S.Text>
                <S.Text>
                  <S.LabelText>Members: {props.members}</S.LabelText>
                  {props.members_details?.map((item) => (
                    <Popover
                      key={item.id}
                      placement="right"
                      title={title(item)}
                      content={content(item)}
                      trigger="click"
                    >
                      <Tag color="purple" style={{ cursor: 'pointer' }}>
                        {item.email}
                      </Tag>
                    </Popover>
                  ))}
                </S.Text>
                <S.Text>
                  <S.LabelText>Apps: {props.apps}</S.LabelText>
                  {props.apps_details?.map((item) => (
                    <Tag key={item.id} color="geekblue">
                      {(item.package == '' ? item.display_name : item.package) +
                        (item.os == '' ? '' : ' (' + item.os + ')')}
                    </Tag>
                  ))}
                </S.Text>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <S.Text>
                  Active:{' '}
                  {lastSeen && lastSeen > Dates.getYesterday()
                    ? 'Yes'
                    : 'No (last seen on ' + lastSeen?.format('lll') + ')'}
                </S.Text>
                <S.Text>Whitelisted: {props.verification_status ? 'Yes' : 'No'}</S.Text>
              </Col>
            </Row>
          </S.InfoHeaderWrapper>
          <S.InfoBottomWrapper>
            <S.DateText>
              {t('common.createdAt')} {Dates.getDate(props.created_on).format('lll')}
            </S.DateText>
          </S.InfoBottomWrapper>
        </S.InfoWrapper>
      </S.Wrapper>
    </S.ItemCard>
  );
};
