import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { AppsTable } from '@app/components/tables/AppsTable';

const DataAppsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('crud.apps.title')}</PageTitle>
      <AppsTable />
    </>
  );
};

export default DataAppsPage;
