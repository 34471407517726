import React from 'react';
import { getAppsData, updateApp, deleteApp, AppsRow } from 'api/sdk.api';
import { useTranslation } from 'react-i18next';
import { Dates } from '@app/constants/Dates';
import { EditTable } from './EditTable/EditTable';

export const AppsTable: React.FC = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('common.id'),
      dataIndex: 'id',
      width: '5%',
      inputType: 'text',
      editable: false,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.displayName'),
      dataIndex: 'display_name',
      width: '35%',
      inputType: 'text',
      editable: true,
      required: false,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.website'),
      dataIndex: 'website',
      width: '10%',
      inputType: 'text',
      editable: true,
      required: false,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.appstore'),
      dataIndex: 'appstore',
      width: '10%',
      inputType: 'text',
      editable: true,
      required: false,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.googleplay'),
      dataIndex: 'googleplay',
      width: '10%',
      inputType: 'text',
      editable: true,
      required: false,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.os'),
      dataIndex: 'os',
      width: '10%',
      inputType: 'text',
      editable: true,
      required: false,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.package'),
      dataIndex: 'package',
      width: '10%',
      inputType: 'text',
      editable: true,
      required: false,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.organization_id'),
      dataIndex: 'organization_id',
      width: '10%',
      inputType: 'number',
      editable: true,
      required: true,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.updatedOn'),
      dataIndex: 'updated_on',
      width: '10%',
      editable: false,
      render: (v: Date) => Dates.getDate(v).format('lll'),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.createdOn'),
      dataIndex: 'created_on',
      width: '10%',
      editable: false,
      render: (v: Date) => Dates.getDate(v).format('lll'),
      sorter: true,
      showSorterTooltip: false,
    },
  ];

  const rowParser = (row: any): AppsRow => {
    // do all the parsing here - I don't know how to do in react
    return row as AppsRow;
  };

  return (
    <EditTable
      getData={getAppsData}
      updateData={updateApp}
      deleteData={deleteApp}
      tableColumns={columns}
      rowParser={rowParser}
    />
  );
};
