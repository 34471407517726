import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';
import jwt_decode from 'jwt-decode';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
  token?: string;
}

export interface LoginResponse {
  token: string;
  expire: number;
  user: UserModel;
}

interface jwt {
  exp: number;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
  email_verified: boolean;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('login', { ...loginPayload }).then(({ data }) => data);

export const loginFromToken = (loginPayload: LoginRequest): Promise<LoginResponse> => {
  const decoded: jwt = jwt_decode(loginPayload.token || '');

  return new Promise((res) =>
    res({
      token: loginPayload.token || '',
      expire: decoded.exp,
      user: {
        id: 1,
        firstName: decoded.given_name,
        lastName: decoded.family_name,
        imgUrl: decoded.picture,
        userName: decoded.email,
        email: {
          name: decoded.email,
          verified: decoded.email_verified,
        },
        phone: {
          number: '',
          verified: false,
        },
        sex: '',
        birthday: '',
        lang: 'en',
        country: '',
        city: '',
        address1: '',
        zipcode: 5211,
        website: '',
        socials: {
          twitter: '',
          facebook: '',
          linkedin: '',
        },
      },
    }),
  );
};

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);
