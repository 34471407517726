import React, { useRef, useState, useEffect, useCallback } from 'react';
import { notification, Col, Row, Popconfirm, Form, TablePaginationConfig, Space } from 'antd';
import type { InputRef } from 'antd';
import { Table } from 'components/common/Table/Table';
import { DataRow, WrappedData, Pagination, Sorter } from 'api/sdk.api';
import { EditCell } from './EditCell';
import { Button } from 'components/common/buttons/Button/Button';
import { Input } from 'components/common/inputs/Input/Input';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { ColumnType, ColumnsType, FilterValue, SorterResult, FilterConfirmProps } from 'antd/lib/table/interface';
import Highlighter from 'react-highlight-words';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';

export interface EditTableProps<U> {
  getData: (pagination: Pagination, sort: Sorter | null, filter: string[] | null) => Promise<WrappedData<U>>;
  updateData: (row: U) => Promise<U>;
  deleteData: (id: number) => Promise<boolean>;
  tableColumns: EditColumnDef<U>;
  rowParser: (row: any) => U;
}

export interface EditColumnDef<U> extends ColumnsType<U> {
  title?: string;
  inputType?: string;
  editable?: boolean;
  dataIndex?: string;
  required?: boolean;
  search?: 'text' | 'list' | 'distinct';
}

const initialPagination: Pagination = {
  current: 1,
  page_size: 10,
};

const convertOrder = (value: string | null | undefined): string | undefined => {
  switch (value) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';
    default:
      return undefined;
  }
};

export const EditTable: React.FC<EditTableProps<any>> = <U extends DataRow>({
  getData,
  updateData,
  deleteData,
  tableColumns,
  rowParser,
}: EditTableProps<U>) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<{ data: U[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [editingKey, setEditingKey] = useState(0);
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination, sort: Sorter | null, filter: string[] | null) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getData(pagination, sort, filter)
        .then((res) => {
          if (isMounted.current) {
            setTableData({ data: res.data, pagination: res.pagination, loading: false });
          }
        })
        .catch((e) => notification.error({ message: e.message }));
    },
    [isMounted, getData],
  );

  useEffect(() => {
    fetch(initialPagination, null, null);
  }, [fetch]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    console.log(filter);
    // TODO: how to form the filter should be decided on the columns level not here
    const queryFilter: string[] = [];
    if (filter != null) {
      for (const key in filter) {
        // TODO: find the filter type for the key (field)
        const values = filter[key];
        if (values != null) {
          const col = mergedColumns.find((c) => c.dataIndex == key);
          switch (col.search) {
            case 'text':
              queryFilter.push(`${key}:"${values[0]}"`);
              break;
            case 'bool-list':
            case 'bool-list-null':
              console.log(values);
              queryFilter.push(`${key}=[${values.map((v) => (v === '' ? 'NULL' : v.toString())).join()}]`);
              break;
            default:
              console.log('unknown search type ' + col.search);
          }
        }
      }
    }

    const ord = convertOrder(sorter.order);
    fetch(
      pagination,
      ord ? { field: sorter.field as string, direction: ord } : null,
      queryFilter.length > 0 ? queryFilter : null,
    );
    cancel();
  };

  const isEditing = (record: U) => record.id === editingKey;

  const edit = (record: Partial<U> & { id: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const save = async (key: React.Key) => {
    try {
      let row = await form.validateFields();
      row = rowParser(row);
      const newData = [...tableData.data];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        let item = newData[index];
        item = await updateData({ ...item, ...row });
        newData.splice(index, 1, item);
      } else {
        // TODO: insert new organization here
        newData.push(row);
      }
      setTableData({ ...tableData, data: newData });
      setEditingKey(0);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleDeleteRow = (rowId: number) => {
    deleteData(rowId)
      .then((result) => {
        if (result) {
          setTableData({ ...tableData, data: tableData.data.filter((item) => item.id !== rowId) });
        } else {
          notification.error({ message: 'Unexpected result false received' });
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  const columns: EditColumnDef<U> = tableColumns.concat([
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      render: (text: string, record: U) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" onClick={() => save(record.id)}>
                  {t('common.save')}
                </Button>
                <Button type="ghost" onClick={cancel}>
                  {t('common.cancel')}
                </Button>
              </>
            ) : (
              <>
                <Button type="ghost" disabled={editingKey !== 0} onClick={() => edit(record)}>
                  {t('common.edit')}
                </Button>
                <Popconfirm title={t('tables.deleteInfo')} onConfirm={() => handleDeleteRow(record.id)}>
                  <Button type="default" danger>
                    {t('tables.delete')}
                  </Button>
                </Popconfirm>
              </>
            )}
          </Space>
        );
      },
    },
  ]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (confirm: (param?: FilterConfirmProps) => void, clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex: string, title: string): ColumnType<U> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Row gutter={8}>
          <Col span={12}>
            <Button
              block
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
          </Col>
          <Col span={12}>
            <Button block onClick={() => clearFilters && handleReset(confirm, clearFilters)} size="small">
              Reset
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const mergedColumns = columns.map((col: any) => {
    let newCols = col;
    if (col.editable) {
      newCols = {
        ...newCols,
        onCell: (record: U) => ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          required: col.required,
        }),
      };
    }

    if (col.search == 'text') {
      newCols = {
        ...newCols,
        ...getColumnSearchProps(col.dataIndex, col.title),
      };
    }

    if (col.search == 'bool-list-null') {
      newCols.filters = [
        {
          text: 'True',
          value: true,
        },
        {
          text: 'False',
          value: false,
        },
        {
          text: 'Not set',
          value: '',
        },
      ];
    }

    if (col.search == 'bool-list') {
      newCols.filters = [
        {
          text: 'True',
          value: true,
        },
        {
          text: 'False',
          value: false,
        },
      ];
    }

    return newCols;
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditCell,
          },
        }}
        bordered
        dataSource={tableData.data}
        rowKey="id"
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          ...tableData.pagination,
          pageSize: tableData.pagination.page_size,
          onChange: cancel,
        }}
        onChange={handleTableChange}
        loading={tableData.loading}
        scroll={{ x: 800 }}
      />
    </Form>
  );
};
