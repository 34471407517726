import React, { useEffect, useState } from 'react';
import { Col, Row, notification } from 'antd';
import { OrganizationsHeader } from '@app/components/main-dashboard/organizations/OrganizationsHeader/OrganizationsHeader';
import { OrganizationsFeed } from '@app/components/main-dashboard/organizations/organizationsFeed/OrganizationsFeed';
import { useResponsive } from '@app/hooks/useResponsive';
import { OrgDashboardItem, getOrgDashboardView } from '@app/api/sdk.api';
import * as S from './Organizations.styles';

export interface OrganizationsFilterState {
  search: string | null;
}

export const OrganizationsCollection: React.FC = () => {
  const [organizations, setOrganizations] = useState<OrgDashboardItem[]>([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<OrgDashboardItem[]>([]);
  const [hasMore] = useState(false);

  const [filters, setFilters] = useState<OrganizationsFilterState>({
    search: null,
  });

  const { isDesktop } = useResponsive();

  useEffect(() => {
    getOrgDashboardView()
      .then((res) => setOrganizations(res))
      .catch((e) => notification.error({ message: e.message }));
  }, []);

  const next = () => {
    getOrgDashboardView().then((res) => setOrganizations(res));
  };

  useEffect(() => {
    if (filters.search) {
      setFilteredOrganizations(
        organizations.filter((item) =>
          item.display_name.toLowerCase().includes((filters.search as string).toLowerCase()),
        ),
      );
    } else {
      setFilteredOrganizations(organizations);
    }
  }, [filters.search, organizations]);

  return (
    <Row gutter={[30, 0]}>
      <Col span={24}>
        <OrganizationsHeader filters={filters} setFilters={setFilters} />
      </Col>

      <Col xs={24} sm={24} md={24} xl={24}>
        <OrganizationsFeed organizations={filteredOrganizations} hasMore={hasMore} next={next} />
      </Col>
    </Row>
  );
};
