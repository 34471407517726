import { httpApi } from '@app/api/http.api';

export interface OrgView {
  id: number;
  display_name: string;
  verification_status: boolean;
  data_addendum: boolean;
  members: number;
  members_details: UserItem[] | null;
  created_on: string;
  updated_on: string;
}

export interface OrgStat {
  organization_id: number;
  mau: number;
  keys: number;
  keys_details: KeyItem[] | null;
  apps: number;
  apps_details: AppItem[] | null;
  last_seen: string;
}

export interface AppItem {
  id: number;
  display_name: string;
  package: string;
  os: string;
}

export interface KeyItem {
  id: number;
  display_name: string;
  pubkey: string;
}

export interface UserItem {
  id: number;
  display_name: string;
  email: string;
}

export interface OrgDashboardItem {
  id: number;
  display_name: string;
  verification_status: boolean;
  data_addendum: boolean;
  created_on: string;
  updated_on: string;
  mau: number;
  keys: number;
  keys_details: KeyItem[] | null;
  members: number;
  members_details: UserItem[] | null;
  apps: number;
  apps_details: AppItem[] | null;
  last_seen: string | null;
}

export const getOrganizations = (): Promise<OrgView[]> => {
  return httpApi.get<OrgView[]>('organizations').then(({ data }) => data);
};

export const getOrgDashboardView = (): Promise<OrgDashboardItem[]> => {
  const org1 = httpApi
    .get<OrgView[]>('organizations', { params: { size: 1000, include_members: true } })
    .then(({ data }) => data);
  const org2 = httpApi.get<OrgStat[]>('keys/orgstats/orgs', { params: { days: 30 } }).then(({ data }) => data);

  return Promise.all([org1, org2]).then((values) => {
    const orgStats = values[1];

    const items = values[0].map((o) => {
      const st = orgStats.find((s) => s.organization_id == o.id);
      return {
        id: o.id,
        display_name: o.display_name,
        verification_status: o.verification_status,
        data_addendum: o.data_addendum,
        created_on: o.created_on,
        updated_on: o.updated_on,
        mau: st ? st.mau : 0,
        keys: st ? st.keys : 0,
        keys_details: st ? st.keys_details : null,
        members: o.members,
        members_details: o.members_details,
        apps: st ? st.apps : 0,
        apps_details: st ? st.apps_details : null,
        last_seen: st ? st.last_seen : null,
      };
    });

    return items.sort((a, b) => b.mau - a.mau);
  });
};

export interface NumberStatsItem {
  date: number;
  number: number;
}

export interface NumberStats {
  total: number;
  prev_total: number;
  timeline: NumberStatsItem[];
}

export const getTotalOrganizations = (): Promise<NumberStats> => {
  return httpApi.get<NumberStats>('keys/orgstats/orgsno', { params: { days: 14 } }).then(({ data }) => data);
};

export const getTotalNodes = (): Promise<NumberStats> => {
  return httpApi.get<NumberStats>('keys/orgstats/nodesno', { params: { days: 14 } }).then(({ data }) => data);
};

export interface Pagination {
  current?: number;
  page_size?: number;
  total?: number;
}

export interface Sorter {
  field: string;
  direction: string;
}

export interface WrappedData<T> {
  data: T[];
  pagination: Pagination;
}

export interface DataRow {
  id: number;
}

const createListParams = (pagination: Pagination, sort: Sorter | null, filter: string[] | null): URLSearchParams => {
  const params = new URLSearchParams();
  if (pagination.current) {
    params.append('page', '' + pagination.current);
  }
  if (pagination.page_size) {
    params.append('size', '' + pagination.page_size);
  }
  params.append('wrapped', 'true');
  if (sort) {
    params.append('orderby', `${sort.field} ${sort.direction}`);
  }
  if (filter) {
    filter.forEach((f) => params.append('filter', f));
  }
  return params;
};

// Organizations
export interface OrganizationRow {
  id: number;
  display_name: string;
  data_addendum: boolean;
  verification_status: boolean | null;
  created_on: Date;
  updated_on: Date;
}

export const getOrganizationsData = (
  pagination: Pagination,
  sort: Sorter | null,
  filter: string[] | null,
): Promise<WrappedData<OrganizationRow>> => {
  return httpApi
    .get<WrappedData<OrganizationRow>>('organizations', {
      params: createListParams(pagination, sort, filter),
    })
    .then(({ data }) => data);
};

export const updateOrganization = (row: OrganizationRow): Promise<OrganizationRow> => {
  return httpApi.put<OrganizationRow>('organizations/' + row.id, row).then(({ data }) => data);
};

export const deleteOrganization = (id: number): Promise<boolean> => {
  return httpApi.delete('organizations/' + id).then((r) => r.status == 204);
};

// Users
export interface UsersRow {
  id: number;
  display_name: string;
  email: string;
  language: string;
  timezone: number;
  created_on: Date;
  updated_on: Date;
}

export const getUsersData = (
  pagination: Pagination,
  sort: Sorter | null,
  filter: string[] | null,
): Promise<WrappedData<UsersRow>> => {
  return httpApi
    .get<WrappedData<UsersRow>>('users', {
      params: createListParams(pagination, sort, filter),
    })
    .then(({ data }) => data);
};

export const updateUser = (row: UsersRow): Promise<UsersRow> => {
  return httpApi.put<UsersRow>('users/' + row.id, row).then(({ data }) => data);
};

export const deleteUser = (id: number): Promise<boolean> => {
  return httpApi.delete('users/' + id).then((r) => r.status == 204);
};

export interface UserToken {
  token: string;
  expire: number;
}

export const getUserToken = (id: number): Promise<UserToken> => {
  return httpApi.get<UserToken>('users/' + id + '/token').then(({ data }) => data);
};

// Keys
export interface KeysRow {
  id: number;
  display_name: string;
  has_data: boolean;
  organization_id: number;
  pubkey: string;
  source: string;
  created_on: Date;
  updated_on: Date;
}

export const getKeysData = (
  pagination: Pagination,
  sort: Sorter | null,
  filter: string[] | null,
): Promise<WrappedData<KeysRow>> => {
  return httpApi
    .get<WrappedData<KeysRow>>('keys/keys', {
      params: createListParams(pagination, sort, filter),
    })
    .then(({ data }) => data);
};

export const updateKey = (row: UsersRow): Promise<UsersRow> => {
  return httpApi.put<UsersRow>('keys/keys/' + row.id, row).then(({ data }) => data);
};

export const deleteKey = (id: number): Promise<boolean> => {
  return httpApi.delete('keys/keys/' + id).then((r) => r.status == 204);
};

// Apps
export interface AppsRow {
  id: number;
  display_name: string;
  website: string;
  appstore: string;
  googleplay: string;
  os: string;
  package: string;
  organization_id: number;
  created_on: Date;
  updated_on: Date;
}

export const getAppsData = (
  pagination: Pagination,
  sort: Sorter | null,
  filter: string[] | null,
): Promise<WrappedData<AppsRow>> => {
  return httpApi
    .get<WrappedData<AppsRow>>('keys/apps', {
      params: createListParams(pagination, sort, filter),
    })
    .then(({ data }) => data);
};

export const updateApp = (row: UsersRow): Promise<AppsRow> => {
  return httpApi.put<AppsRow>('keys/apps/' + row.id, row).then(({ data }) => data);
};

export const deleteApp = (id: number): Promise<boolean> => {
  return httpApi.delete('keys/apps/' + id).then((r) => r.status == 204);
};

// AppsKeys
export interface AppsKeysRow {
  id: number;
  app_id: number;
  key_id: number;
  created_on: Date;
  updated_on: Date;
}

export const getAppsKeysData = (
  pagination: Pagination,
  sort: Sorter | null,
  filter: string[] | null,
): Promise<WrappedData<AppsKeysRow>> => {
  return httpApi
    .get<WrappedData<AppsKeysRow>>('keys/appsKeys', {
      params: createListParams(pagination, sort, filter),
    })
    .then(({ data }) => data);
};

export const updateAppKey = (row: UsersRow): Promise<AppsKeysRow> => {
  return httpApi.put<AppsKeysRow>('keys/appsKeys/' + row.id, row).then(({ data }) => data);
};

export const deleteAppKey = (id: number): Promise<boolean> => {
  return httpApi.delete('keys/appsKeys/' + id).then((r) => r.status == 204);
};
