import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { MainCard } from '@app/components/main-dashboard/common/MainCard/MainCard';
import { TotalNodesChart } from '@app/components/main-dashboard/totalNodes/TotalNodesChart/TotalNodesChart';
import { getTotalNodes, NumberStats } from '@app/api/sdk.api';
import { Dates } from '@app/constants/Dates';
import { formatNumberWithCommas, getDifference } from '@app/utils/utils';
import * as S from './TotalNodes.styles';

export const TotalNodes: React.FC = () => {
  const [totalNodes, setTotalNodes] = useState<NumberStats | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    getTotalNodes()
      .then((res) => setTotalNodes(res))
      .catch((e) => notification.error({ message: e.message }));
  }, []);

  const { totalNodesData, days } = useMemo(
    () => ({
      totalNodesData: {
        data: totalNodes ? totalNodes.timeline.map((item) => item.number) : [],
      },
      days: totalNodes ? totalNodes.timeline.map((item) => Dates.getDate(item.date).format('L')) : [],
    }),
    [totalNodes],
  );

  const isIncreased = Number(totalNodes?.total) > Number(totalNodes?.prev_total);

  return (
    <MainCard isSider>
      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Row wrap={false} justify="space-between">
            <Col>
              <S.Title level={2}>{t('main.totalNodes')}</S.Title>
            </Col>

            <Col>
              <S.ValueText $color={isIncreased ? 'success' : 'error'}>
                {isIncreased ? <CaretUpOutlined /> : <CaretDownOutlined />}{' '}
                {totalNodes && getDifference(totalNodes?.total, totalNodes?.prev_total)}
              </S.ValueText>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row wrap={false} justify="space-between" gutter={[20, 20]}>
            <Col>
              <S.Text>{formatNumberWithCommas(totalNodes?.total ?? 0)}</S.Text>
            </Col>
            <Col flex={1}>
              <TotalNodesChart xAxisData={days} orgData={totalNodesData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </MainCard>
  );
};
