import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';

const loadScript = (src: string) =>
  new Promise<void>((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const googleClientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '';
const googleAuthUrl = 'https://accounts.google.com/gsi/client';

const GoogleAuth: React.FC = () => {
  const googleButton = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [, setLoading] = useState(false);

  useEffect(() => {
    function handleCredentialResponse(response: { credential: string }) {
      setLoading(true);
      dispatch(doLogin({ email: '', password: '', token: response.credential }))
        .unwrap()
        .then(() => navigate('/'))
        .catch((err) => {
          console.log(err);
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }

    loadScript(googleAuthUrl)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: googleClientId,
          callback: handleCredentialResponse,
        });
        // eslint-disable-next-line
        google.accounts.id.renderButton(googleButton.current as HTMLDivElement, { type: 'standard', theme: 'outline', size: 'large' });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${googleClientId}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, [dispatch, navigate]);

  return <div ref={googleButton}></div>;
};

export default GoogleAuth;
