import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';
import history from '@app/components/router/history';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error?.response?.status === 401) {
    history.replace('/auth-admin/login');
  }
  if (error?.response?.status === 403) {
    history.replace('/403');
  }
  throw new ApiError<ApiErrorData>(
    error.response?.status,
    error.response?.data.message || error.message,
    error.response?.data,
  );
});

export interface ApiErrorData {
  code: number;
  message: string;
}
