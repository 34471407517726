import React from 'react';
import { getKeysData, updateKey, deleteKey, KeysRow } from 'api/sdk.api';
import { useTranslation } from 'react-i18next';
import { Dates } from '@app/constants/Dates';
import { EditTable } from './EditTable/EditTable';

export const KeysTable: React.FC = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('common.id'),
      dataIndex: 'id',
      width: '5%',
      inputType: 'text',
      editable: false,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.displayName'),
      dataIndex: 'display_name',
      width: '30%',
      inputType: 'text',
      editable: true,
      required: true,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.pubkey'),
      dataIndex: 'pubkey',
      width: '35%',
      inputType: 'text',
      editable: true,
      required: true,
      sorter: true,
      showSorterTooltip: false,
      search: 'text',
    },
    {
      title: t('common.organization_id'),
      dataIndex: 'organization_id',
      width: '10%',
      inputType: 'number',
      editable: true,
      required: false,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.updatedOn'),
      dataIndex: 'updated_on',
      width: '10%',
      editable: false,
      render: (v: Date) => Dates.getDate(v).format('lll'),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.createdOn'),
      dataIndex: 'created_on',
      width: '10%',
      editable: false,
      render: (v: Date) => Dates.getDate(v).format('lll'),
      sorter: true,
      showSorterTooltip: false,
    },
  ];

  const rowParser = (row: any): KeysRow => {
    // do all the parsing here - I don't know how to do in react
    return row as KeysRow;
  };

  return (
    <EditTable
      getData={getKeysData}
      updateData={updateKey}
      deleteData={deleteKey}
      tableColumns={columns}
      rowParser={rowParser}
    />
  );
};
